import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export enum IconPosition {
  LEFT = "left",
  RIGHT = "right",
}

export enum ImagePosition {
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export enum Dropdowns {
  SOLUTION = "solution",
  RESOURCES = "resources",
  COMPANY = "company",
  ABOUT = "about",
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export enum TypeformIds {
  PROVIDER_WAITLIST = "GZ1lf5uc",
  DEVELOPER_CREDIT = "Jp58YQB2",
}

export enum RedirectLinks {
  DISCORD = "https://sphn.wiki/discord",
  TELEGRAM = "https://sphn.wiki/tg",
  LINKEDIN = "https://sphn.wiki/linkedin",
  YOUTUBE = "https://sphn.wiki/yt",
  COMMUNITY = "https://community.spheron.network/",
  APP = "https://app.spheron.network",
  APP_LOGIN = "https://app.spheron.network/#/login",
  APP_SIGNUP = "https://app.spheron.network/#/signup",
  PROVIDER_APP = "https://provider.spheron.network",
  CONSOLE_APP = "https://console.spheron.network",
  FIZZ_NODE_APP = "https://fizz.spheron.network/",
  DOCS = "https://docs.spheron.network",
  CHANGELOG = "/changelog",
  PARTNER = "/partner",
  BLOG = "https://blog.spheron.network",
  TWITTER = "https://sphn.wiki/x",
  GITHUB = "https://github.com/spheronFdn",
  REPORTURL = "https://b4t4v7fj3cd.typeform.com/to/Ta5C0UDZ",
  MAREKTINGKIT = "https://drive.google.com/drive/folders/1yiq-1Jr0qBVou00b32jJsJj4cB4hVFIc",
  INSTAGRAM = "https://www.instagram.com/spheronhq/",
  CLINPM = "https://www.npmjs.com/package/@spheron/cli",
  STORAGENPM = "https://www.npmjs.com/package/@spheron/storage",
  CUSTOM_FORM = "https://b4t4v7fj3cd.typeform.com/sphnent",
  COMPUTE_DOCS = "https://docs.spheron.network/compute",
  SDK_DOCS = "https://docs.spheron.network/sdk/",
  CLI_DOCS = "https://docs.spheron.network/user-guide/protocol-cli",
  IPFSGATEWAY_DOCS = "https://docs.spheron.network/gateways/dedicated/",
  MARKETPLACE_GUIDE = "https://docs.spheron.network/marketplace-guide/",
  DECENTRALIZED_HOSTING_DOCS = "https://docs.spheron.network/static/deployment/logs/",
  DECENTRALIZED_COMPUTE_DOCS = "https://docs.spheron.network/compute/cluster/compute/",
  STORAGE_DOCS = "https://docs.spheron.network/sdk/",
  DEMO = "https://meetings.hubspot.com/prashant-maurya/product-demo-landing",
  IPNS = "https://docs.spheron.network/sdk/storage/#publish-ipns",
  SPHERONGPT = "https://sphn.notion.site/SpheronGPT-bd176612fe4742df892a5266613bd32c",
  DOCS_STORAGE = "https://docs.spheron.network/sdk/storage/",
  BUCKET_DOMAIN = "https://docs.spheron.network/storage/buckets/domain/",
  ANNOUNCEMENT_LINK = "https://blog.spheron.network/dive-into-spherons-v1-whitepaper",
  SPACESHIP = "https://b4t4v7fj3cd.typeform.com/to/eY9Z8Ihf",
  STORAGE_PRICING = "https://spheron.network/pricing/compare/#storage",
  STATIC_PRICING = "https://spheron.network/pricing/compare/#webapp",
  WHITEPAPER_PDF = "/Spheron-Whitepaper.pdf",
  SUPERNODERZ = "https://supernoderz.com/",
  EARNING = "https://b4t4v7fj3cd.typeform.com/spheronnode",
  CONTACT_US = "mailto:info@spheron.network",
}

export const showFizzNotifyModal = () => {
  if (typeof window === "undefined") return false;
  if (sessionStorage.getItem("fizzNotifyModalClosed") === "true") return false;
  const cookies = document.cookie.split(";").map((s) => s.trim());
  const cookie = cookies.find((c) => c.startsWith("fizzNotifyEmail="));
  if (cookie) !cookie.split("=")[1];
  else return true;
};
