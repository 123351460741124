export const mainMeta = {
  title: "Spheron Network - Decentralised GPU Network",
  desc: "Instant access to on-demand compute to scale your most powerful applications. No cumbersome infrastructure development; simply deploy and scale.",
  keyword:
    "IPFS, Decentralised Storage, Filecoin, IPFS Gateway, Polygon, Ethereum Storage, Polygon Storage, Arweave, Decentralised Compute, P2P Compute, P2P Cloud, Peer to Peer Cloud, Postgress SQL,  IPNS, Encryption, Access Control, Compute Marketplace, Persistent Storage, GPU, Bare metal, Server, Jupyter notebook, AI, Drupal, CMS, DePIN, DePINs, Decentralised Physical Infrastructure, DeVINs, Decentralised Virtual Infrastructure",
  icon: "/favicon.ico",
  metaImage: "/meta-og-image.png?version=1",
  url: "https://spheron.network",
};

export const pricingMeta = {
  ...mainMeta,
  title: "Pricing - Spheron Network",
  metaImage: "https://spheron.network/metaimage-2.png",
  keyword: `${mainMeta.keyword} Pricing, Low cost, Subscription, Onchain, Decentralized cloud storage, Decentralized app`,
};

export const changelogMeta = {
  ...mainMeta,
  metaImage:
    "https://spheron.network/changelog/default-changelog-og-image-v2.png",
};

export const partnerMeta = {
  ...mainMeta,
  desc: "Let our spaceship program be your launch pad to success, providing the extra boost your business needs through decentralized cloud infrastructure.",
  title: "Spaceship - Spheron Network",
  metaImage: "/meta-og-image.png?version=1",
  keyword: `${mainMeta.keyword} Credits, Partner Program, Affiliate, Pricing, Low cost, Subscription, Onchain, Decentralized cloud, Decentralized app, storage, compute`,
};

export const typeformMeta = {
  ...mainMeta,
};
